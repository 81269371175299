import React, { createContext, ReactNode, useContext } from 'react';

import { BRANDING } from '../utils/branding';

export const BrandingContext = createContext({
  ...BRANDING,
});

type Props = {
  children: ReactNode;
};

export const BrandingProvider = ({ children }: Props) => (
  <BrandingContext.Provider value={BRANDING}>
    {children}
  </BrandingContext.Provider>
);

export const useBrandingContext = () => useContext(BrandingContext);
