import { Box, Icon } from '@angellist/adapt';
import React from 'react';

type Props = { label: string; icon: any };

const MenuItemText = ({ label, icon }: Props) => (
  <Box gap="100" display="flex" alignItems="center">
    <Icon named={icon || 'circle'} />
    {label}
  </Box>
);

export default MenuItemText;
